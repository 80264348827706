html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

header {
	min-height: 60px;
}

@font-face {
	font-family: 'poppins-regular';
	src: url('../fonts/poppins-regular.ttf');
}

@font-face {
	font-family: 'poppins-bold';
	src: url('../fonts/poppins-bold.ttf');
}
h1, h2{
	line-height: 1.5;
}

h1,
h2,
h3,
h4 {
	font-family: 'poppins-bold';
}

.fs-7 {
	font-size: 0.9rem;
}

.fs-8 {
	font-size: 0.8rem;
}

* {
	box-sizing: border-box;
	font-family: "poppins-regular";
	font-size: 1.2rem;
}

*:focus {
	box-shadow: 0 0 10px 0.25rem var(--box-secondary-rgba) !important;
	outline-width: 0px;
}

.poppins-bold-text {
	font-family: "poppins-bold";
}

.poppins-regular-text {
	font-family: "poppins-regular";
}

@font-face {
	font-family: 'bl-icon-set';
	src: url('../fonts/bl-icon-set.eot?ohdo8w');
	src: url('../fonts/bl-icon-set.eot?ohdo8w#iefix') format('embedded-opentype'),
		url('../fonts/bl-icon-set.ttf?ohdo8w') format('truetype'),
		url('../fonts/bl-icon-set.woff?ohdo8w') format('woff'),
		url('../fonts/bl-icon-set.svg?ohdo8w#bl-icon-set') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'bl-icon-set' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-bx-ai:before {
	content: "\e958";
}

.icon-redis:before {
	content: "\e957";
}

.icon-bx-servlet:before {
	content: "\e956";
}

.icon-bx-ui-forms:before {
	content: "\e954";
}

.icon-bx-yaml:before {
	content: "\e955";
}

.icon-bx-ftp:before {
	content: "\e953";
}

.icon-bx-web-support:before {
	content: "\e952";
}

.icon-bx-oshi:before {
	content: "\e951";
}

.icon-bx-ini:before {
	content: "\e950";
}

.icon-python:before {
	content: "\e94f";
}

.icon-bx-wddx:before {
	content: "\e94e";
}

.icon-bx-pdf:before {
	content: "\e945";
}

.icon-bx-image:before {
	content: "\e946";
}

.icon-bx-mail:before {
	content: "\e947";
}

.icon-bx-mssql:before {
	content: "\e94a";
}

.icon-bx-mysql:before {
	content: "\e94b";
}

.icon-bx-oracle:before {
	content: "\e94c";
}

.icon-bx-validate:before {
	content: "\e94d";
}

.icon-chrome:before {
	content: "\e949";
}

.icon-bx-derby:before {
	content: "\e941";
}

.icon-bx-esapi:before {
	content: "\e942";
}

.icon-bx-hypersql:before {
	content: "\e943";
}

.icon-bx-mariadb:before {
	content: "\e944";
}

.icon-bx-postgresql:before {
	content: "\e948";
}

.icon-community:before {
	content: "\e940";
}

.icon-copy:before {
	content: "\e93f";
}

.icon-docker:before {
	content: "\e93e";
}

.icon-bl-framework:before {
	content: "\e92d";
}

.icon-caching:before {
	content: "\e93d";
}

.icon-tower:before {
	content: "\e93c";
}

.icon-bl-horn:before {
	content: "\e936";
}

.icon-patreon:before {
	content: "\e937";
}

.icon-vscode:before {
	content: "\e935";
}

.icon-discourse:before {
	content: "\e938";
}

.icon-facebook:before {
	content: "\e939";
}

.icon-lambda:before {
	content: "\e93a";
}

.icon-linkedin:before {
	content: "\e93b";
}

.icon-meta-programming:before {
	content: "\e933";
}

.icon-serverless:before {
	content: "\e934";
}

.icon-close:before {
	content: "\e92e";
}

.icon-dependency:before {
	content: "\e92f";
}

.icon-framework:before {
	content: "\e930";
}

.icon-multi-runtime:before {
	content: "\e931";
}

.icon-testing:before {
	content: "\e932";
}

.icon-colon:before {
	content: "\e92c";
}

.icon-arrow-down:before {
	content: "\e900";
}

.icon-arrow-left:before {
	content: "\e901";
}

.icon-arrow-right:before {
	content: "\e902";
}

.icon-arrow-up:before {
	content: "\e903";
}

.icon-Asset-45:before {
	content: "\e904";
}

.icon-backslash:before {
	content: "\e905";
}

.icon-blog:before {
	content: "\e906";
}

.icon-check:before {
	content: "\e907";
}

.icon-chevron-down:before {
	content: "\e908";
}

.icon-chevron-left:before {
	content: "\e909";
}

.icon-chevron-right:before {
	content: "\e90a";
}

.icon-chevron-up:before {
	content: "\e90b";
}

.icon-debug:before {
	content: "\e90c";
}

.icon-download:before {
	content: "\e90d";
}

.icon-dynamic:before {
	content: "\e90e";
}

.icon-ecosystem:before {
	content: "\e90f";
}

.icon-event:before {
	content: "\e910";
}

.icon-experience:before {
	content: "\e911";
}

.icon-functions:before {
	content: "\e912";
}

.icon-github:before {
	content: "\e913";
}

.icon-home:before {
	content: "\e914";
}

.icon-java:before {
	content: "\e915";
}

.icon-minus:before {
	content: "\e916";
}

.icon-modular:before {
	content: "\e917";
}

.icon-multidevice:before {
	content: "\e918";
}

.icon-open:before {
	content: "\e919";
}

.icon-opensource:before {
	content: "\e91a";
}

.icon-play:before {
	content: "\e91b";
}

.icon-plus:before {
	content: "\e91c";
}

.icon-podcast:before {
	content: "\e91d";
}

.icon-prices:before {
	content: "\e91e";
}

.icon-puzzles:before {
	content: "\e91f";
}

.icon-schedule:before {
	content: "\e920";
}

.icon-screen:before {
	content: "\e921";
}

.icon-scripting:before {
	content: "\e922";
}

.icon-search:before {
	content: "\e923";
}

.icon-server:before {
	content: "\e924";
}

.icon-slack:before {
	content: "\e925";
}

.icon-slash:before {
	content: "\e926";
}

.icon-synamic:before {
	content: "\e927";
}

.icon-syntax:before {
	content: "\e928";
}

.icon-tooling:before {
	content: "\e929";
}

.icon-world-class-support:before {
	content: "\e92a";
}

.icon-x:before {
	content: "\e92b";
}

.icon-world-class-support:before {
	content: "\e92a";
}

.icon-x:before {
	content: "\e92b";
}

body {
	background-color: transparent;
}

//background animation
@keyframes bganimation {
	0% {
		background-position:
			-20% -20%,
			60% 60%,
			-30% 80%,
			90% -90%;
	}

	50% {
		background-position:
			50% 100%,
			-800% 90%,
			70% 0%,
			0% 650%;
	}

	100% {
		background-position:
			-20% -20%,
			60% 60%,
			-30% 80%,
			90% -90%;
	}
}

html {
	background: radial-gradient(ellipse at center, var(--box-primary-rgba-30) 0%, var(--box-body-bg-rgba) 70%),
		radial-gradient(ellipse at center, var(--box-secondary-rgba) 0%, var(--box-body-bg-rgba) 70%),
		radial-gradient(ellipse at center, var(--box-primary-rgba-30) 0%, var(--box-body-bg-rgba) 70%),
		var(--box-body-bg);
	background-repeat: no-repeat, no-repeat, no-repeat;
	background-size: 2000px 2000px, 2000px 2000px, 2000px 2000px;
	background-attachment: fixed;
	animation: bganimation 30s infinite;
}

//buttons
.btn-primary {
	border: 1px solid var(--box-primary);
	background: var(--box-primary);
	color: var(--box-bgBoxes) !important;
}

.btn-outline-primary {
	background-color: var(--box-gradient-diagonal);
	border: 1px solid var(--box-primary);
	color: var(--box-primary);
}

.btn-primary:hover,
.btn-outline-primary:hover,
.btn-primary:focus-visible,
.btn-outline-primary:focus-visible {
	background: var(--box-gradient-diagonal);
	border: 1px solid var(--box-primary);
	color: var(--box-bgBoxes) !important;
}

//forms
.form-control,
.form-select {
	background-color: var(--box-input-bg);
	border-color: var(--box-primary) !important;
}

.form-control:focus,
.form-select:focus {
	background-color: var(--box-bgBoxes);
	border-color: var(--box-secondary) !important;
	box-shadow: 0 0 0 0.25rem var(--box-secondary-rgba) !important;
}

//text colors
.text-primary {
	color: var(--box-primary) !important;
}

.text-danger {
	color: var(--box-danger);
}

.text-boxes {
	color: var(--box-bgBoxes);
}

a {
	text-decoration: none;
	color: var(--box-primary);
}

a:hover {
	color: var(--box-secondary) !important;
}

//latest news
.badge-bgBox {
	background: var(--box-bgBox-75);
	color: var(--box-light);
}

.badge {
	font-weight: normal;
}

#news .card {
	max-width: 350px;
	height: 370px;
	padding: 0;
	border-radius: 8px;
	box-sizing: border-box;
	overflow: hidden;
	--bs-card-bg: var(--box-bgBoxes);
}

#news .card:hover {
	background: var(--box-gradient-diagonal);
	color: var(--box-bgBoxes) !important;
	box-shadow: 0 0 30px 10px var(--box-secondary-rgba);
}

#news .card h3 {
	color: var(--box-light);
}

#news .card:hover h3 {
	color: var(--box-bgBoxes);
}

#news .card * {
	transition: 0.2s ease all;
}

#news .card img {
	margin: 0;
	min-height: 143px !important;
	object-fit: cover;
	display: block;
	transition: margin-top 0.3s ease;
}

#news .card:hover img {
	opacity: 0.5;
	object-fit: cover;
	object-position: bottom;
}

#news .card .focus-content {
	display: block;
	visibility: hidden;
}

#news .card:hover .focus-content {
	visibility: visible;
	transition: 0.1s ease all;
}

#news .card:hover img,
#news .card:focus-within img {
	margin-top: -27px;
}

//download section
.z-index-6 {
	z-index: 6;
}

.download .code-toolbar .token{
	font-size: 0.8rem !important;
}

.download .copy-to-clipboard-button span{
	font-size: 0.8rem !important;
}

.download .language-java {
	font-size: 0.9rem !important;
}
.dropdown-download .btn-outline-primary, .dropdown-download a{
	color: var(--box-light);
}
.btn-outline-primary:hover .dropdown-download .btn-outline-primary,
.dropdown-download .btn-outline-primary:hover,
.dropdown-download .btn-outline-primary:active,
.dropdown-download .btn-outline-primary:focus,
.dropdown-download .btn.show  {
	background: var(--box-bgBox-75) !important;
	border-color: var(--box-secondary) !important;
	box-shadow: 0 0 0 0.25rem var(--box-secondary-rgba);
	color: var(--box-light) !important;
}
.bg-gradient-round {
	background: url("../images/bg-gradients.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.btn-outline-primary .text-download-list,
a .text-description-link {
	color: var(--box-light);
}

.btn-outline-primary .icon-left {
	background: var(--box-gradient-vertical);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.btn-outline-primary .icon-right {
	color: var(--box-secondary-rgba);
}

.btn-outline-primary:hover .text-download-list,
.btn-outline-primary:hover .icon-right,
.btn-outline-primary:focus .icon-right,
.btn-outline-primary:focus .text-download-list {
	color: var(--box-bgBoxes) !important;
}

.btn-outline-primary:hover .icon-left,
.btn-outline-primary:focus .icon-left {
	background: var(--box-bgBoxes);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

//Contributors
.bg-box-75 {
	background-color: var(--box-bgBox-75) !important;
}

.nav-flex {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}

//FAQs
.accordion-item:first-of-type>.accordion-header .accordion-button {
	border-top-right-radius: 0px;
	border-top-left-radius: 0px;
}

.accordion-button:hover {
	background: var(--box-gradient-diagonal);
	color: var(--box-bgBoxes) !important;
}

.accordion-button:active {
	background-color: var(--box-secondary-rgba-20) !important;
}

.accordion {
	--bs-accordion-bg: var(--box-bgBox-75);
	--bs-accordion-border-radius: none;
}

.accordion-button {
	text-align: center;
}

.accordion-button:not(.collapsed) {
	color: var(--box-light);
	background-color: var(--box-secondary-rgba-20);
	box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
	content: "\e916";
	background-image: none;
	font-family: 'bl-icon-set';
}

.accordion-button.collapsed::after {
	content: "\e91c";
	background-image: none;
	font-family: 'bl-icon-set';
}

.accordion-item {
	color: var(--bs-accordion-color);
	background-color: var(--bs-accordion-bg);
	border-top: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
	border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
	border-left: none;
	border-right: none;
}

//footer
.bg-boxes {
	background-color: var(--box-bgBoxes);
}

/* CONTRIBUTORS STYLES */
.profile-card {
	background: transparent;
	border-radius: 10px;
	position: relative;
	transition: 0.6s;
	width: 150px;
	text-align: center;
	padding: 10px;
}

.caption {
	display: none;
}

.profile-card:hover {
	background: var(--box-gradient-diagonal);
	border: 1px solid var(--box-primary);
	border-radius: 10px;
	box-shadow: 0 0 22px rgba(51, 51, 51, 0.4);
	color: var(--box-bgBoxes);
	margin-top: -20px;
}

.profile-card .img {
	margin: 0 auto;
	position: relative;
	transition: 0.6s;
	width: 105px;
	z-index: 99;
}

.profile-card:hover .img {
	margin-top: -30%;
	width: 80px;
}

.profile-card:hover .caption {
	display: inherit;
	position: inherit;
}

.profile-card:hover .caption .icon-github:hover {
	color: var(--box-bgBox-50);
}

.profile-card:hover .caption p {
	font-size: 15px;
	margin: 2px 0 9px;
}

.h-200-px {
	height: 150px;
}

.caption .social-links a {
	color: var(--box-bgBoxes);
}

//why BOXLANG page
.text-gradient {
	background: -webkit-linear-gradient(180deg, var(--box-primary) 35%, var(--box-secondary) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.border-primary {
	border: 1px solid var(--box-primary) !important;
}

//Key features
.text-highlights {
	color: var(--box-highlights);
}

.text-light {
	color: var(--box-light);
}

.size-icon {
	height: 55px;
	width: 70px;
}

li::marker {
	color: var(--box-primary);
}

pre[class*="language-"] {
	background: var(--box-bgBox-75);
}

div.code-toolbar>.toolbar {
	top: 1em;
	right: 1em;
}

.code-toolbar .token {
	font-size: 0.9rem !important;
}

.copy-to-clipboard-button span{
	font-size: 0.9rem !important;
}

.language-java {
	font-size: 1rem !important;
}

//Plans
.badge-gradient {
	background: var(--box-gradient-diagonal);
	color: var(--box-bgBoxes);
}

.check-list li {
	list-style: none;
	padding-inline-start: 0.5rem;
}

ul.check-list {
	padding-inline-start: 15px;
}

.check-list li::marker {
	content: "\e907";
	font-family: 'bl-icon-set';
	font-size: x-small;
	color: var(--box-primary);
}

.dropdown-toggle {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.dropdown-toggle::after {
	font-family: 'bl-icon-set';
	content: "\e908";
	border: 0px;
	font-size: 6px;
	margin-left: 4px;
}

.dropdown-toggle[aria-expanded="true"]::after {
	transform: rotate(180deg);
}

.card-plan {
	max-width: 400px;
}

//plan details
.nav-pills .nav-link {
	background: var(--box-primary-rgba-10);
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link:focus {
	background: var(--box-secondary);
	color: var(--box-bgBoxes) !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	color: var(--box-light) !important;
	background-color: var(--box-bgBox-75);
}

.circle-checked {
	width: 27px;
	height: 27px;
	border: 1px solid var(--box-primary);
}

.circle-checked i {
	display: none;
}

.nav-link:hover .circle-checked {
	border-color: var(--box-bgBoxes);
}

.nav-link.active .circle-checked {
	background-color: var(--box-primary);
	border-color: var(--box-primary);
}

.nav-link.active .circle-checked i {
	color: var(--box-bgBox-75);
	display: flex;
	font-size: 0.6rem;
}

.carousel-indicators {
	position: relative;
}

.carousel-indicators [data-bs-target] {
	width: 10px;
	height: 10px;
	background-color: var(--box-primary) !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	width: 1.4rem;
	height: 1.4rem;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
	filter: none !important;
}

.carousel-control-prev,
.carousel-control-next {
	opacity: 1;
}

.carousel-control-next-icon {
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%2300FF78"><path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>');
}

.carousel-control-prev-icon {
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%2300FF78"><path d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg>');
}

.carousel-control-prev,
.carousel-control-next {
	bottom: auto;
	width: auto;
}

//table
.body-table table {
	border-collapse: separate;
}

.top-header {
	top: 67px;
}

.table-wrapper {
	position: relative;
}

.sticky-column {
	position: sticky;
	left: 0;
	z-index: 1;
}


.table {
	--bs-table-bg: none;
	--bs-table-color: none;
	--bs-table-hover-bg: var(--box-bgBox-50);
	margin-left: -5px;
}

.bg-primary {
	background-color: var(--box-primary) !important;
}

th,
td {
	border: none;
	font-size: 0.9rem;
	font-weight: normal;
	vertical-align: middle;
}

.body-table th,
td {
	border-radius: var(--bs-border-radius);
}

.table> :not(caption)>*>* {
	padding: 0.5rem 0.9rem;
}

.tab-content>.active {
	display: flex;
}

.padding-x-5 {
	padding-left: 5px;
	padding-right: 5px;
}

//breadcrumbs
.breadcrumbs a,
.breadcrumbs i {
	font-size: 0.9rem;
}

.breadcrumbs a:last-child {
	color: var(--box-light);
}

@media only screen and (max-width:767px) {

	//Key features
	.size-icon {
		height: auto;
		width: 50px;
	}

	//plan tables
	.table-fixed-column-inner {
		overflow-x: scroll;

		.table {
			margin-bottom: 0.25rem;
		}
	}

	.sticky-column {
		position: sticky;
		top: 0;
		z-index: 2;
	}
}

@media only screen and (max-width:1200px) {
	* {
		font-size: 1.1rem;
	}

}

@media (orientation: landscape) {
	#carouselExampleDark {
		height: 800px;
	}
}

@media only screen and (min-width:844px) and (orientation: landscape) {
	#carouselExampleDark {
		height: 650px;
	}
}

@media (orientation: portrait) {
	#carouselExampleDark {
		height: 950px;
	}
}

@media only screen and (min-width:768px) and (orientation: portrait) {
	#carouselExampleDark {
		height: 600px;
	}
}

//menu
.language-color:hover {
	background-color: var(--bs-dropdown-link-hover-bg);
}

.bg-dark {
	background-color: var(--box-navbar-bg) !important;
}

.dropdown-menu.show {
	background:
		radial-gradient(ellipse at top, rgba( var( --box-primary-rgb ), .3 ) 0, rgba( var( --box-body-bg-rgb ), .2 ) 80%),
		linear-gradient(to right, rgba( var( --box-primary-rgb ), .5 ) 0, rgba( var( --box-secondary-rgb ), .5) 90%),
		rgba( var( --box-body-bg-rgb ), .9);
}

nav .dropdown-item {
	color: var(--box-light) !important;
}

nav .dropdown-item:active,
.dropdown-item:hover,
.dropdown-item:focus {
	font-size: 0.8rem;
	display: flex;
	justify-content: space-between;
	color: var(--box-navbar-bg) !important;
	background-color: var(--box-dropdown-item-hover-bg);
}

.social-media-link {
	color: var(--box-light) !important;
}

.nav-link, .social-media-link {
	background: transparent;
	border-width: 1px;
	border-style: solid;
	border-color: transparent;
	border-radius: var( --box-border-radius );
	transition: border-color .5s;
}

nav .nav-link:hover,
.nav-link:focus,
footer .nav-link:hover,
.nav-link:focus,
.social-media-link:hover {
	background: var( --box-gradient-diagonal );
	border: 1px solid var( --box-primary );
	color: var( --box-bgBoxes ) !important;
	box-shadow: 0 0 0 1px rgba( var( --box-secondary-rgb ), .3 ), 0 1px 6px 6px rgba( var( --box-secondary-rgb ), .3 );
	border-radius: var( --box-border-radius );
}

.floating-btn {
	position: sticky;
	z-index: 1000;
	text-align: right;
	margin-right: 1%;
	margin-bottom: 1%;

	transition-duration: 0.5s;
	transition-timing-function: linear;
}

.contact-form-btn {
	width: 60px;
	height: 60px;
	z-index: 1000;
	left: 100%;
	top: 77%;
	bottom: 20px;
}


.offcanvas {
	background-color: var(--box-bgBox-75) !important;
}

.flex-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.mb-n80 {
	margin-bottom: -80px;
}

.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.w-150px {
	width: 150px;
}